import { getAppConfig } from '@services/config';

import {
    SapicFundingType,
    SapicFundingTypeV5,
    SapicProductTypeV4,
    SapicProductTypeV5,
    SimulatedCartQuoteV4,
    SimulatedCartV4,
    SimulatedCartV5,
} from '@app-types/api/sapic';
import { ParcoursVirtuel } from '@app-types/api/vapi';
import { QUOTE_TYPES } from '@app-types/cart';
import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { FaiType, MobilePlanType, ObligationType } from '@app-types/plan';
import { PRICE_TYPE } from '@app-types/price';
import { Product, ProductType, SimulatedCartProduct } from '@app-types/product';
import { SimulatedQuote } from '@app-types/simulator/virtual-cart';

import { ApiDiscountMapper } from '@helpers/mappers/api-discount.mapper';
import { paths } from '@helpers/path';

import { PlanRange, PlanTypeId } from '@constants/plan';
import { PLAY, TECHNOLOGY } from '@constants/provider';

const appConfig = getAppConfig();

export class SapicMapper {
    static mapToProduct(sapicProduct: SapicProductTypeV4 | SapicProductTypeV5, isRenewal: boolean = false) {
        const fundings =
            (sapicProduct?.modesDeFinancement ?? sapicProduct?.financements)?.map(SapicMapper.fundingMapper) ?? [];
        const funding = fundings?.[0];

        const initialDeposit = funding?.initialDeposit ?? 0;
        const sumODRDiscounts = sapicProduct.promotions.reduce((sum, { reduction, type }) => {
            if (type !== DiscountEnumType.ODR) {
                return sum;
            }
            return sum + reduction;
        }, 0);
        const priceWithOdr = initialDeposit - sumODRDiscounts;
        const price = priceWithOdr < 1 ? 1 : priceWithOdr;

        const simulatedCart = sapicProduct.panierSimule as SimulatedCartV4 | SimulatedCartV5;

        return {
            id: sapicProduct.url,
            gencode: sapicProduct.gencode,
            urlKey: sapicProduct.url,
            url: paths.productDetail(sapicProduct.type, sapicProduct.url, isRenewal),
            inStock: sapicProduct.estEnStock,
            brand: sapicProduct.marque,
            name: sapicProduct.nom,
            image: sapicProduct.image,
            categories: sapicProduct.categories,
            stickers: sapicProduct.etiquetteAnimCo
                ? // Use VariantState.INFO to display the sticker when trilogy fix server component
                  [{ label: sapicProduct.etiquetteAnimCo, color: 'INFO' as const }]
                : undefined,
            price,
            recommended: sapicProduct.recommande ?? false,
            details: {
                price: {
                    initial: sapicProduct.prix.initial,
                    subsidized: sapicProduct.prix.subventionne,
                    forever: sapicProduct.prix.pourToujours,
                    final: sapicProduct.prix.final,
                },
                // v4
                discounts: sapicProduct.promotions.map(ApiDiscountMapper.mapPromotionSapic),
                fundings,
            },
            colors: sapicProduct?.couleurs ?? [],
            simulatedCart: SapicMapper.getSimulatedCart(simulatedCart),
            neededElements: sapicProduct?.necessiteElements?.flatMap((el) =>
                el.alternatives?.map((alt) => ({ gencode: alt.identifiant, label: alt.libelle })),
            ), // need label for futur usage ?
        };
    }

    static createSimulatedQuote(quote: SimulatedCartQuoteV4 | ParcoursVirtuel): SimulatedQuote {
        return {
            products: [],
            type: QUOTE_TYPES.ACQUISITION,
            current: 'estCourant' in quote ? quote.estCourant : false,
        };
    }

    static createSimulatedProducts(quote: SimulatedCartQuoteV4 | ParcoursVirtuel): Product[] {
        const products: Product[] = [];
        if ('elements' in quote) {
            quote.elements.forEach((product) => {
                const simulatedProduct: Partial<Product> = {
                    gencode: product.composantCommercial.identifiant,
                    details: {
                        price: {
                            initial: product.prixAffiche.prixCatalogue,
                            final: product.prixAffiche.prixAvecPromotions,
                            forever: product.prixAffiche.prixPourToujours,
                            subsidized: product.prixAffiche.prixPourToujours,
                            type: product.prixAffiche.type as PRICE_TYPE,
                        },
                        discounts: product.promotionsCalculees.promotionsAppliquees.map(
                            ApiDiscountMapper.mapPromotionVapi,
                        ),
                        fundings: [], // @TODO: when SAPIC V5 will have funding
                    },
                    type: ProductType.ACCESSORY, // @TODO: when SAPIC V5 will have type
                };
                products.push(simulatedProduct as Product);
            });
        } else {
            quote.produits.forEach((product) => {
                const simulatedProduct: Partial<Product> = {
                    gencode: product.gencode,
                    details: {
                        price: {
                            initial: product.prix.initial,
                            final: product.prix.final,
                            forever: product.prix.pourToujours,
                            subsidized: product.prix.subventionne,
                            type: product.typePrix as PRICE_TYPE,
                        },
                        discounts: product.promotions.map(ApiDiscountMapper.mapPromotionSapic),
                        fundings: product.financements.map(SapicMapper.fundingMapper),
                    },
                    type: product.type as ProductType, // @TODO: create mapper sapic type => our type
                    brand: product.marque,
                };
                products.push(simulatedProduct as Product);
            });
        }

        return products;
    }

    static getSimulatedCart(cart: SimulatedCartV4 | SimulatedCartV5): SimulatedCartProduct | undefined {
        if (!cart || !cart.parcours) {
            return undefined;
        }

        const simulatedCart: SimulatedCartProduct = {
            fundings: [],
            quotes: [],
        };

        cart.parcours.forEach((quote) => {
            const simulatedQuote: SimulatedQuote = SapicMapper.createSimulatedQuote(quote);

            simulatedQuote.products = SapicMapper.createSimulatedProducts(quote);
            simulatedCart.quotes.push(simulatedQuote);
        });

        if ('financements' in cart) {
            cart.financements?.forEach((funding) => {
                simulatedCart.fundings.push(SapicMapper.fundingMapper(funding));
            });
        }

        return simulatedCart;
    }

    static fundingMapper(funding: SapicFundingType | SapicFundingTypeV5): FundingType {
        if ('apportInitial' in funding) {
            // V4
            return {
                label: SapicMapper.getFundingLabels(funding),
                type: funding.type,
                favorite: funding.prefere,
                initialDeposit: funding.apportInitial,
                amountToFinance: funding.montantAFinancer,
                totalFinancingCost: funding.coutTotalFinancement,
                APR: funding.tAEG,
                interestAmount: funding.montantInteret,
                fixedInterestRate: funding.tauxDebiteurFixe,
                installmentCount: funding.nbrEcheances,
                monthlyAmount: funding.montantMensualite,
            };
        }

        const fundingData = funding.propositionsCommerciales[0]!;

        return {
            label: funding.libelle,
            type: funding.type,
            favorite: funding.prefere,
            initialDeposit: fundingData.apportInitial,
            amountToFinance: fundingData.montantAFinancer,
            totalFinancingCost: fundingData.coutTotalFinancement,
            APR: fundingData.tAEG,
            interestAmount: fundingData.montantInterets,
            fixedInterestRate: fundingData.tauxDebiteurFixe,
            installmentCount: fundingData.nbrEcheances,
            monthlyAmount: fundingData.montantMensualite,
        };
    }

    static getFundingLabels(funding: SapicFundingType): string {
        switch (funding.type) {
            case FundingEnumType.COMPTANT:
                return 'Comptant';
            case FundingEnumType.EDP:
                return 'Facilité de paiement sans frais';
            default: {
                return 'Financement smartphone';
            }
        }
    }

    static mapToFai(apiData: SapicProductTypeV5): FaiType {
        const product = SapicMapper.mapToProduct(apiData, false);
        const faiData: Partial<FaiType> = {
            downRates: apiData.debitDescendant || '',
            upRates: apiData.debitAscendant || '',
            rentalFees: apiData.fraisDeLocation || 0,
            technology: apiData.technologie as TECHNOLOGY,
            play: apiData.jeu as PLAY,
            categoryType: apiData.typeCategorieFai || '',
            obligation: apiData?.dureeEngagement || ObligationType.NONE,
            obligationLabel: SapicMapper.getObligationLabel(apiData?.dureeEngagement, true),
            commercialSeries: apiData.serieCommerciale ?? apiData.classification,
            rangeNg: apiData.gammeNg ?? 'xgbox',
            range: apiData.gamme,
            type: apiData.type,
            isDefault: appConfig.defaultPlans.simulator.fai === apiData.gencode,
        };
        return { ...product, ...faiData } as FaiType;
    }

    static mapToMobilePlan(apiData: SapicProductTypeV4 | SapicProductTypeV5): MobilePlanType {
        const product = SapicMapper.mapToProduct(apiData, false);
        const renewalPlanLabel =
            apiData.libelleEnveloppeVoix && apiData.libelleEnveloppeVoix !== 'Appels illimités'
                ? `${apiData.libelleEnveloppeVoix} ${apiData.libelleEnveloppeData}`
                : apiData.libelleEnveloppeData;

        return {
            ...product,
            gencode: apiData.gencode,
            price: apiData.prix.initial,
            fundings: [],
            typeId: SapicMapper.mapSapicTypeToPlanType(apiData.type),
            obligation: apiData?.dureeEngagement || ObligationType.NONE,
            obligationLabel: SapicMapper.getObligationLabel(apiData?.dureeEngagement, false),
            name: apiData.nom.replace('Avantages Smartphone', ''),
            dataEnvelope: apiData.libelleEnveloppeData || '',
            isDarwin: false,
            hasDoubleSim: false,
            hasLowPrice: false,
            isExcluPro: apiData.excluPro,
            renewalLabelDataEnvelope: renewalPlanLabel ?? '',
            isDefault: appConfig.defaultPlans.simulator.mobile === apiData.gencode,
            commercialRange: SapicMapper.mapSapicRangeToCommercialRange(apiData.gammeCommerciale),
        };
    }

    static getObligationLabel(obligation?: ObligationType, isYears: boolean = false): string {
        switch (obligation) {
            case ObligationType.MONTHLY_24:
                return `engagement ${isYears ? '2 ans' : '24 mois'}`;
            case ObligationType.MONTHLY_12:
                return `engagement ${isYears ? '1 an' : '12 mois'}`;
            default:
                return 'sans engagement';
        }
    }

    static mapSapicRangeToCommercialRange(range?: string): PlanRange {
        switch (range) {
            case 'forfait_sans_mobile':
                return PlanRange.WITHOUT_MOBILE;
            case 'forfait_avec_mobile':
                return PlanRange.WITH_MOBILE;
            default:
                return PlanRange.DEFAULT;
        }
    }

    static mapSapicTypeToPlanType(type: string): PlanTypeId {
        const typeValues = Object.values(PlanTypeId);
        const typeValue = typeValues.find((value) => value === type);
        return typeValue || PlanTypeId.DEFAULT;
    }
}
